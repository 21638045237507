<template>
  <div>
    <h2 v-if="team.desc" class="content__title title">Описание</h2>
    <p v-if="team.desc" class="content__paragraph">{{ team.desc }}</p>
    <div class="content__title content__title--party">
      <h2 class="title">Участники</h2>
      <button v-if="team.is_admin" @click="onSidebarOpen" type="button" class="link">
        <img src="@/assets/img/team/member-plus.svg" alt="Member invite">
        <span>Пригласить участника</span>
      </button>
    </div>
    <MemberList
      @changeAdmin="changeAdmin"
      @updateTeam="fetchMembers"
      :members="members"
      :admin="team.is_admin"
      :teamId="team.id"
      controls="list"
    />
    <SidebarRight
      title="Пригласить участника"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
    >
      <h2 class="content__title title">Пригласить по номеру телефона или ID</h2>
      <form @submit.prevent="onSendInvite" class="sidebar-manage__form">
        <div v-if="!form.phone" class="form-group">
          <label for="count">ID пользователя</label>
          <div class="form-control">
            <input
              id="count"
              type="number"
              class="form-control__input"
              name="count"
              placeholder="Введите ID пользователя"
              v-model.number="form.id"
              min="1"
            />
          </div>
        </div>
        <div v-if="!form.id" class="form-group">
          <label>Телефон</label>
          <vue-tel-input
            v-model="phone_value"
            defaultCountry="RU"
            mode="international"
            type="tel"
            class="phonepicker"
            :class="{ 'phonepicker--error': $v.form.phone.$dirty && !is_phone_valid && phone_value  }"
            @validate="onValidedPhone"
            @blur="$v.form.phone.$touch()"
            :inputOptions="phone_picker_options"
            validCharactersOnly
          >
            <template slot="arrow-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                <path
                  d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z"
                  fill="#2B93E7"></path>
              </svg>
            </template>
          </vue-tel-input>
          <div class="form-group__error" v-if="$v.form.phone.$dirty && !is_phone_valid && phone_value">Недопустимое
            значение для номера телефона
          </div>
        </div>
        <button class="button button--stretched">Пригласить</button>
      </form>
      <h2 class="content__title title">Пригласить по ссылке</h2>
      <div class="form-group">
        <div class="form-control">
          <input
            id="link"
            type="text"
            class="form-control__input"
            name="link"
            v-model="invite_link"
          />
        </div>
      </div>
      <div class="content__title--party content__title--end">
        <button @click="onCopyLink" class="button">Скопировать ссылку</button>
      </div>
    </SidebarRight>
  </div>
</template>

<script>
import MemberList from '@/components/MemberList'
import SidebarRight from '@/components/SidebarRight'
import {VueTelInput} from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import {minLength} from 'vuelidate/lib/validators'

export default {
  name: 'TeamDetail',
  props: ['team'],
  components: {
    SidebarRight,
    MemberList,
    VueTelInput
  },
  validations: {
    form: {
      phone: {minLength: minLength(7)},
    }
  },
  data() {
    return {
      members: [],
      show_sidebar: false,
      form: {
        id: '',
        phone: '',
      },
      phone_value: '',
      is_phone_valid: null,
      phone_picker_options: {
        placeholder: 'Введите номер телефона'
      },
    }
  },
  computed: {
    invite_link() {
      return `${process.env.VUE_APP_SERVER_URL}teams/my?hash=${this.$props.team.hash}`
    }
  },
  mounted() {
    this.fetchMembers()
  },
  methods: {
    changeAdmin() {
      this.$emit('changeAdmin')
    },
    fetchMembers() {
      this.$store.dispatch('team/GET_MEMBERS', { id: this.$route.params.id, status: 'in_team' })
        .then(response => {
          this.members = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
          this.$router.push({name: 'my-teams'})
        })
    },
    onSidebarOpen() {
      this.show_sidebar = true
    },
    onValidedPhone(number) {
      this.is_phone_valid = number.valid
      if (number.valid) {
        this.form.phone = number.number
      } else {
        this.form.phone = ''
      }
    },
    onSendInvite() {
      this.$store.dispatch('team/POST_INVITE', {id: this.$route.params.id, form: this.form})
        .finally(() => {
          this.show_sidebar = false
          this.form.id = ''
          this.form.phone = ''
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Внимание!',
            text: 'Приглашение успешно отправлено'
          })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка отправки приглашения'
          })
        })
    },
    onCopyLink() {
      navigator.clipboard.writeText(this.invite_link)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Внимание!',
            text: 'Ссылка скопирована в буфер обмена'
          })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка копирования ссылки'
          })
        })
    }
  }
}
</script>
